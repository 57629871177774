<template>
  <div>
    <TosListVuetify serviceName="gateway" entityName="rules" :headers="headers">
      <template #customFilters="slotProps">
        <v-container fluid>
          <v-row>
            <v-col cols="3">
              <v-select :label="tLabel('Goods sys. subtype')" v-model="slotProps.data.sysSubtype"
                :items="sysSubtypes" />
            </v-col>
            <v-col cols="3">
              <v-text-field :label="tLabel('Goods type code')" v-model="slotProps.data.goodsTypeCode" />
            </v-col>
          </v-row>
        </v-container>
      </template>
      <template v-slot:editF-XXX="slotProps">
        <v-container fluid>
          <v-row>
            <v-col>
              <v-select :label="tLabel('Goods sys. subtype')" v-model="slotProps.data.sysSubtype" :items="sysSubtypes"
                :rules="[rules.required]" :error-messages="slotProps.errors.sysSubtype" />
            </v-col>
            <v-col>
              <v-text-field :label="tLabel('Goods type code')" v-model="slotProps.data.goodsTypeCode"
                :rules="[rules.required]" :error-messages="slotProps.errors.goodsTypeCode" />
            </v-col>
            <v-col>
              <v-text-field :label="tLabel('HS code')" v-model="slotProps.data.hsCode"
                :error-messages="slotProps.errors.hsCode" />
            </v-col>
            <v-col>
              <v-select :label="tLabel('Count Type')" v-model="slotProps.data.countType" clearable :items="countTypes"
                :error-messages="slotProps.errors.countType" />
            </v-col>
          </v-row>
        </v-container>
      </template>
    </TosListVuetify>

  </div>

</template>


<script>
import TosListVuetify from '@/components/anagrafiche/TosListVuetify';
import FormatsMixins from "@/mixins/FormatsMixins";

export default {
  name: "Rules",
  data() {
    return {
      sysSubtypes: [],
      countTypes: [],
      headers: [
        { text: "", value: "action" },
        { text: `${this.tLabel('name')}`, value: "name" },
        { text: `${this.tLabel('enabled')}`, value: "enabled" },
        { text: `${this.tLabel('source Type')}`, value: "sourceType" },
        { text: `${this.tLabel('source Url')}`, value: "sourceUrl" },
        { text: `${this.tLabel('source auth')}`, value: "sourceAuth" },
        { text: `${this.tLabel('processor Name')}`, value: "processorName" },
        { text: `${this.tLabel('dest Type')}`, value: "destType" },
        { text: `${this.tLabel('dest Url')}`, value: "destUrl" },
        { text: `${this.tLabel('dest auth')}`, value: "destAuth" },
        { text: `${this.tLabel('filter Key')}`, value: "filterKey" },
        { text: `${this.tLabel('filter Value')}`, value: "filterValue" },
        { text: `${this.tLabel('Dms Document Type')}`, value: "dmsDocumentType" },
        { text: `${this.tLabel('Dms Tags')}`, value: "dmsTags" },
      ],
    };
  },
  mounted(){
    this.loadCombo();
  },
  
  methods: {
    async prepareItem(data) {
    },
    async loadCombo() {
      this.sysSubtypes = await this.$api.get(this.$apiConfiguration.BASE_PATH_YARD + "lookups/goodsTypes");
      this.countTypes = await this.$api.get(this.$apiConfiguration.BASE_PATH_YARD + "lookups/countTypes");
    }    
  },
  components: {TosListVuetify},
  mixins: [FormatsMixins],
};
</script>
